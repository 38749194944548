import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../container/Home/Home";
import { Menu } from "./Menu/Menu";
import AboutUs from "../container/AboutUs/AboutUs";
import VerifyEmail from "../container/VerifyEmail/VerifyEmail";
import Profile from "../container/Profile/Profile";
import Posts from "../container/Posts/Posts";
import Category from "../container/Category/Category";
import Saved from "../container/Saved/Saved";
import Settings from "../container/Settings/Settings";
import PrivacePolicy from "../container/PrivacePolicy/PrivacePolicy";
import ForgotPass from "../container/ForgotPassword/ForgotPassword";
import CategoryPosts from "../container/CategoryPosts/CategoryPosts";
import React, { useState } from "react";
// import useWebSocket, { ReadyState } from "react-use-websocket";
// import Notfications from "./Notfications/Notfications";
import Share from "../container/Share/Share";
import DefaultCategory from "../container/DefaultCategory/DefaultCategory";
// export const Context = React.createContext([]);

function AllRoutes() {
  const [notfications, setNotfications] = useState([]);
  // const { readyState, sendJsonMessage } = useWebSocket(
  //   process.env.REACT_APP_API_SERVER_SOCKET,
  //   {
  //     onOpen: () => {
  //       console.log("Connected!");
  //     },
  //     onClose: () => {
  //       console.log("Disconnected!");
  //     },
  //     onMessage: (e) => {
  //       const data = JSON.parse(e.data);
  //       switch (data.type) {
  //         case "notifications":
  //           // const obj = JSON.  (data.notify)
  //           // console.log("not",data.all);
  //           for (let d in data.all) {
  //             console.log(d);
  //             console.log("local", JSON.parse(localStorage.getItem("user")).id);
  //             if (JSON.parse(localStorage.getItem("user")).id === +d) {
  //               // console.log("--------------------------------");

  //               // console.log(JSON.parse(data.all[d]));
  //               // console.log("--------------------------------");
  //               setNotfications(JSON.parse(data.all[d]));
  //             }
  //           }
  //           break;
  //         default:
  //           console.log(e);
  //           console.error("Unknown message type!");
  //           break;
  //       }
  //     },
  //   }
  // );
  return (
    <>
      {/* <Context.Provider value={{ sendJsonMessage }}> */}
      <BrowserRouter>
        <Menu notfications={notfications} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/category/:category" element={<DefaultCategory />} />

          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/privacePolicy" element={<PrivacePolicy />} />
          <Route path="/confirm/:uid/:token/:code" element={<ForgotPass />} />
          <Route path="/profile" element={<Profile />}>
            <Route path="posts" element={<Posts />} />
            <Route path="category" element={<Category />}>
              <Route path=":name" element={<CategoryPosts />}></Route>
            </Route>
            <Route path="saved" element={<Saved />} />
          </Route>
          <Route path="/activate/:uid/:token" element={<VerifyEmail />} />
          <Route path="/share/:id" element={<Share />} />
        </Routes>
      </BrowserRouter>
      {/* </Context.Provider> */}
    </>
  );
}
export default AllRoutes;
