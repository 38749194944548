import React, { useEffect, useState } from "react";
import "./home.css";
import Post from "../../components/Post/Post";
import axios from "axios";
import Loading from "./../../components/Loading/Loading";
function Home() {
  const [post, setPost] = useState([]);
  const [loading, setLoading] = useState(false);
  // create user and token
  useEffect(() => {
    setLoading(true);
    axios
      .get(process.env.REACT_APP_API_SERVER + "/quotes/")
      .then((resp) => {
        // const data = resp.data.reverse(resp.data)
        setPost(resp.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);
  console.log(post);
  return (
    <>
      <div className="home_page">
        <div className="section_2">
       {post.map(p => {
        return p?.published && <Post post={p} setPost = {setPost}></Post>
       })}
        </div>
      </div>
      {loading && <Loading></Loading>}
    </>
  );
}
export default Home;
