import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Post from "../../components/Post/Post";

function Share() {
  const params = useParams();
  // console.log(params);
  const [post, setPost] = useState({});
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_SERVER + "/quotes/" + params?.id)
      .then((r) => {
        // console.log(r);
        setPost(r.data);
      });
  }, []);
  return (
    <div style={{ display: "flex", alignItem:"center", justifyContent:"center", padding: "50px"}}>
      <Post post={post}/>
    </div>
  );
}

export default Share;
