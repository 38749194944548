import axios from "axios";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/Loading/Loading";

function VerifyEmail() {
    const params = useParams()
    const navigate = useNavigate()
    console.log(params);
    useEffect(() => {
        axios.post(process.env.REACT_APP_API_SERVER+"/auth/users/activation/",
            { uid: params.uid, token: params.token }
        ).then(resp => {
            console.log(resp.data)
            navigate("/")
            alert("Email verified. You can Login.")
        }).catch((error) => {
            if (error.response) {
                console.log("error.response ", error.response);
                if(error.response.data.detail) {
                    alert(error.response.data.detail)

                } else {
                    alert("Please resend activation link.")
                }
                navigate("/")
            } else if (error.request) {
                console.log("error.request ", error.request);
            } else if (error.message) {
                console.log("error.message ", error.message);
            }
            navigate("/")
        });
    }, [])
    return (
        <>
           <Loading/> 
        </>

    );
}

export default VerifyEmail;
