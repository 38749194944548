import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
// import { Context } from "../Router";
import "./comment.css";

function Comment({ comment, getComments, post, getPosts }) {
  const [user, setUser] = useState("");
  const [userToken, setUserToken] = useState("");
  const [optionsModal, setOptionsModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [replyModal, setReplyModal] = useState(false);
  const [replyComment, setReplyComment] = useState("");
  const [editingCommentValue, setEditingCommentValue] = useState("");
  const [replyId, setReplyId] = useState("");
  const [childrenId, setChildrenId] = useState([]);
  const [parentUserId, setParentUserId] = useState("");
  // create token
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
    const token = JSON.parse(localStorage.getItem("token"));
    if (token) {
      setUserToken("JWT " + token.access);
    }
  }, []);
  const [com, setCom] = useState("");

  // delete
  const deleteComment = (id) => {
    axios.delete(process.env.REACT_APP_API_SERVER+"/comments/" + id).then((resp) => {
      console.log(resp);
      getComments(post.id);
      setCom("");
      getPosts()
    });
  };

  // edit
  const doneEditing = (id, event) => {
    setIsEditing(false);
    event.preventDefault();
    axios
      .patch(process.env.REACT_APP_API_SERVER+"/comments/" + id + "/", {
        body: editingCommentValue,
      })
      .then((resp) => {
        console.log(resp);
        setCom("");
        getComments(post.id);
        setEditingCommentValue("");
      });
  };

  //context for socket
  // const data = useContext(Context);

  //reply
  const replaySubmit = (e) => {
    console.log(replyId);
    e.preventDefault();
    axios
      .post(process.env.REACT_APP_API_SERVER+"/comments/",
        {
          body: replyComment,
          //replyid
          parent: replyId,

          quote: comment?.quote,
          //parent user id
          parent_user_id: parentUserId,
          //postb anoxi id
          post_author_id: post.author.id,

          //grox
          user_id: user.id
        },
        { headers: { Authorization: userToken } }
      )
      .then((resp) => {
        setCom("");
        setReplyComment("");
        getComments(post.id);
        setReplyModal(!replyModal);
        showReplies(replyId);

        // data.sendJsonMessage({
        //   type: "reply",
        //   message: "I replied this comment",
        //   //groxna
        //   user_id: user.id,
        //   // postn
        //   post: post.id,
        //   //posti hexinka
        //   author_id: post.author.id,
        //   // parenti useri id
        //   parent_user_id: parentUserId,
        // });
      }).catch((error) => {
        console.log(error);
        if (error.response) {
          console.log("error.response ", error.response);
        } else if (error.request) {
          console.log("error.request ", error.request);
        } else if (error.message) {
          console.log("error.message ", error.message);
        }
      });;
  };

  //showReplies
  const showReplies = (id) => {
    setChildrenId([...childrenId, id]);
  };

  const showComment = (comment) => {
    return (
      <div
        className={!comment?.parent ? "comment" : "children"}
        style={
          childrenId?.includes(comment?.parent) || !comment?.parent
            ? { display: "block" }
            : { display: "none" }
        }
      >
        <div className="comment_user_and_body" key={comment?.id}>
          <img src={comment?.user?.avatar} className="add_comment_avatar" alt="user_avatar"/>
          <div className="name_and_body">
            <p className="username">
              {comment?.user?.first_name} {comment?.user?.last_name}
            </p>
            {!isEditing ? (
              <p className="comment_body">{comment?.body}</p>
            ) : com === comment?.id ? (
              <form
                onSubmit={(e) => doneEditing(comment?.id, e)}
                style={{ display: "flex", gap: "10px" }}
              >
                <input
                  className="edit_comment_input"
                  value={editingCommentValue}
                  onChange={(e) => {
                    setEditingCommentValue(e.target.value);
                  }}
                />
                <input type="submit" value="done" className="done_btn" />
              </form>
            ) : (
              ""
            )}
            {isEditing && com !== comment?.id && (
              <p className="comment_body">{comment?.body}</p>
            )}
          </div>
          {user?.id === comment?.user?.id ? (
            <button
              className="comment_options"
              onClick={() => {
                setOptionsModal(!optionsModal);
                setCom(comment?.id);
              }}
            >
              ...
            </button>
          ) : (
            <button className="not_login_user"></button>
          )}
          {optionsModal && com === comment?.id && (
            <div className="comment_options_modal">
              {/* edit */}
              <button
                className="comment_edit_btn"
                onClick={() => {
                  setIsEditing(true);
                  setReplyModal(false);
                  setOptionsModal(false);
                  setEditingCommentValue(comment?.body);
                  setCom(comment?.id);
                }}
              >
                Edit
              </button>
              {/* delete */}
              <button
                className="comment_delete_btn"
                onClick={() => {
                  deleteComment(comment?.id);
                  setOptionsModal(false);
                }}
              >
                Delete
              </button>
            </div>
          )}
        </div>
        <div>
          <div className="edit_show_hide_buttons">
            {/* replay */}
            {!replyModal && (
              <button
                onClick={() => {
                  setReplyModal(!replyModal);
                  setCom(comment?.id);
                  setIsEditing(false);
                }}
              >
                Reply
              </button>
            )}
            {childrenId.includes(comment?.id) ? (
              <button
                onClick={() => {
                  childrenId.splice(childrenId.indexOf(comment?.id), 1);
                  setChildrenId([...childrenId]);
                  setReplyModal(false);
                }}
              >
                <b>Hide replies</b>
              </button>
            ) : comment?.children?.length > 0 ? (
              <button
                onClick={() => {
                  showReplies(comment?.id);
                  setReplyModal(false);
                }}
              >
                <b>Show replies</b>
              </button>
            ) : (
              ""
            )}
          </div>
          {replyModal && com === comment?.id && (
            <form
              onSubmit={(e) => replaySubmit(e)}
              style={{ display: "flex", gap: "10px", paddingLeft: "65px" }}
            >
              <input
                placeholder="Write replay..."
                className="reply_input"
                onClick={() => {
                  setReplyId(comment?.id);
                  setParentUserId(comment?.user?.id);
                }}
                value={replyComment}
                onChange={(e) => {
                  setReplyComment(e.target.value);
                }}
              />
              <button type="submit" className="reply_btn">
                Send reply
              </button>
            </form>
          )}
        </div>
        {comment?.children?.length > 0 &&
          comment?.children?.map((comment) => {
            return <div key={comment?.id}>{showComment(comment)}</div>;
          })}
      </div>
    );
  };
  return <>{showComment(comment)}</>;
}
export default Comment;
