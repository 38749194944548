import { useEffect, useState } from "react";
import axios from "axios";
import "./settings.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Loading from "../../components/Loading/Loading";

function Settings() {
  const [userToken, setUserToken] = useState("");
  const [current_password, setCurrent_password] = useState("");
  const [modal, setModal] = useState({ sure: false, password: false });
  const navigate = useNavigate();
  const [user, setUser] = useState("");
  const [showPass1, setShowPass1] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [showPass3, setShowPass3] = useState(false);
  const [showPass4, setShowPass4] = useState(false);
  const [showPass5, setShowPass5] = useState(false);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));
    const userr = JSON.parse(localStorage.getItem("user"));

    if (token) {
      setUserToken("JWT " + token.access);
      setUser(userr);
    } else {
      navigate("/");
    }
  }, []);

  const [passError, setPassError] = useState(false);

  function deleteAccount(event) {
    event.preventDefault();
    console.log(current_password);
    axios
      .post(process.env.REACT_APP_API_SERVER + "/auth/jwt/create/", {
        email: user.email,
        password: current_password,
      })
      .then((resp) => {
        setModal({ sure: true, password: false });
      })
      .catch((error) => {
        if (error.response) {
          console.log("error.response ", error.response);
          if (error.response.data.detail) {
            setPassError(true);
          }
        } else if (error.request) {
          console.log("error.request ", error.request);
        } else if (error.message) {
          console.log("error.message ", error.message);
        }
      });
  }

  function yesDelete() {
    axios
      .delete(process.env.REACT_APP_API_SERVER + "/auth/users/me/", {
        headers: {
          Authorization: userToken,
        },
        data: {
          current_password,
        },
      })
      .then((resp) => {
        console.log(resp);
        setModal({ sure: false, password: false });
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/");
        window.location.reload();
      })
      .catch((error) => {
        if (error.response) {
          console.log("error.response ", error.response);
        } else if (error.request) {
          console.log("error.request ", error.request);
        } else if (error.message) {
          console.log("error.message ", error.message);
        }
      });
  }

  function deleteAccWithFbOrGoogle() {
    axios
      .delete(process.env.REACT_APP_API_SERVER + "/auth/users/me/", {
        headers: { Authorization: userToken },
        data: { current_password: "WillChangeLater" },
      })
      .then((resp) => {
        console.log(resp);
        setModal({ sure: false, password: false });
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/");
        window.location.reload();
      })
      .catch((error) => {
        if (error.response) {
          console.log("error.response ", error.response);
        } else if (error.request) {
          console.log("error.request ", error.request);
        } else if (error.message) {
          console.log("error.message ", error.message);
        }
      });
  }

  // change password
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [changePassError, setChangePassError] = useState({
    re_new: "",
    current: "",
  });
  const [changeSuccess, setChangeSuccess] = useState("");

  function changePass(data) {
    if (user.auth_provider === "email") {
      if (data.new_password === data.re_new_password) {
        axios
          .post(
            process.env.REACT_APP_API_SERVER + "/auth/users/set_password/",
            data,
            {
              headers: {
                Authorization: userToken,
              },
            }
          )
          .then((resp) => {
            console.log(resp);
            reset();
            setChangeSuccess("Your password has been changed.");
          })
          .catch((error) => {
            if (error.response) {
              console.log("error.response ", error.response);
              if (error.response.data.current_password) {
                setChangePassError({
                  ...changePassError,
                  current: error.response.data.current_password,
                });
              }
            } else if (error.request) {
              console.log("error.request ", error.request);
            } else if (error.message) {
              console.log("error.message ", error.message);
            }
          });
      } else {
        setChangePassError({
          ...changePassError,
          re_new: "The password and re-enter password doesn't match",
        });
      }
    } else {
      if (data.new_password === data.re_new_password) {
        axios
          .post(
            process.env.REACT_APP_API_SERVER + "/auth/users/set_password/",
            {
              current_password: "WillChangeLater",
              new_password: data.new_password,
              re_new_password: data.re_new_password,
            },
            {
              headers: {
                Authorization: userToken,
              },
            }
          )
          .then((resp) => {
            console.log(resp);
            reset();
            setChangeSuccess("Your password has been changed.");
            axios
              .patch(
                process.env.REACT_APP_API_SERVER + "/auth/users/me/",
                { auth_provider: "email" },
                { headers: { Authorization: userToken } }
              )
              .then((respo) => {
                console.log(respo);
                localStorage.setItem(JSON.stringify("user", respo.data));
                setUser(respo.data);
              })
              .catch((error) => {
                if (error.response) {
                  console.log("error.response ", error.response);
                } else if (error.request) {
                  console.log("error.request ", error.request);
                } else if (error.message) {
                  console.log("error.message ", error.message);
                }
              });
          })
          .catch((error) => {
            if (error.response) {
              console.log("error.response ", error.response);
              if (error.response.data.current_password) {
                setChangePassError({
                  ...changePassError,
                  current: error.response.data.current_password,
                });
              }
            } else if (error.request) {
              console.log("error.request ", error.request);
            } else if (error.message) {
              console.log("error.message ", error.message);
            }
          });
      } else {
        setChangePassError({
          ...changePassError,
          re_new: "The password and re-enter password doesn't match",
        });
      }
    }
  }

  const [loading, setLoading] = useState(false);

  function forgotPassword() {
    setLoading(true);
    axios
      .post(process.env.REACT_APP_API_SERVER + "/auth/users/reset_password/", {
        email: user.email,
      })
      .then((res) => {
        console.log("act", res);
        setLoading(false);
        alert("Լink sent. Check your email.");
      })
      .catch((error) => {
        if (error.response) {
          console.log("error.response ", error.response);
          setLoading(false);
        } else if (error.request) {
          console.log("error.request ", error.request);
          setLoading(false);
        } else if (error.message) {
          console.log("error.message ", error.message);
          setLoading(false);
        }
      });
  }
  return (
    <>
      <div className="account_settings_div">
        <h2 className="account_settings">Account Settings</h2>
        {user?.auth_provider === "email" ? (
          <>
            <hr />
            <div
              className="change_password"
              onClick={() => setChangeSuccess("")}
            >
              <form
                onSubmit={handleSubmit(changePass)}
                onClick={() =>
                  setChangePassError({
                    ...changePassError,
                    re_new: "",
                    current: "",
                  })
                }
              >
                <h2>Change Password</h2>
                {changeSuccess && (
                  <p className="successText">{changeSuccess}</p>
                )}
                <div>
                  <input
                    type={showPass1 ? "text" : "password"}
                    placeholder="Current password"
                    {...register("current_password", {
                      required: true,
                    })}
                  />
                  {showPass1 ? (
                    <svg
                      onClick={() => setShowPass1(false)}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-eye"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                    </svg>
                  ) : (
                    <svg
                      onClick={() => setShowPass1(true)}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-eye-slash"
                      viewBox="0 0 16 16"
                    >
                      <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                      <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                      <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                    </svg>
                  )}

                  {errors.current_password &&
                    errors.current_password.type === "required" && (
                      <span className="error_for_change_pass">This is required*</span>
                    )}
                  {changePassError.current && (
                    <span className="error_for_change_pass">{changePassError.current}</span>
                  )}
                </div>

                <div>
                  <input
                    type={showPass2 ? "text" : "password"}
                    placeholder="New password"
                    {...register("new_password", {
                      required: true,
                      maxLength: 15,
                      minLength: 8,
                      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
                    })}
                  />
                  {showPass2 ? (
                    <svg
                      onClick={() => setShowPass2(false)}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-eye"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                    </svg>
                  ) : (
                    <svg
                      onClick={() => setShowPass2(true)}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-eye-slash"
                      viewBox="0 0 16 16"
                    >
                      <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                      <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                      <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                    </svg>
                  )}
                  {errors.new_password &&
                    errors.new_password.type === "required" && (
                      <span className="error_for_change_pass">This is required*</span>
                    )}
                  {errors.new_password &&
                    errors.new_password.type === "maxLength" && (
                      <span className="error_for_change_pass">Password can't be more than 15 characters*</span>
                    )}
                  {errors.new_password &&
                    errors.new_password.type === "minLength" && (
                      <span className="error_for_change_pass">Password can't be less than 8 characters*</span>
                    )}
                  {errors.new_password &&
                    errors.new_password.type === "pattern" && (
                      <span className="error_for_change_pass">Password must be containe uppercase, lowercase and number*</span>
                    )}
                </div>

                <div>
                  <input
                    type={showPass3 ? "text" : "password"}
                    {...register("re_new_password", {
                      required: true,
                      maxLength: 15,
                      minLength: 8,
                      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
                    })}
                    placeholder="Re-enter password"
                  />
                  {showPass3 ? (
                    <svg
                      onClick={() => setShowPass3(false)}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-eye"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                    </svg>
                  ) : (
                    <svg
                      onClick={() => setShowPass3(true)}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-eye-slash"
                      viewBox="0 0 16 16"
                    >
                      <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                      <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                      <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                    </svg>
                  )}
                  {errors.re_new_password &&
                    errors.re_new_password.type === "required" && (
                      <span className="error_for_change_pass">This is required*</span>
                    )}
                  {errors.re_new_password &&
                    errors.re_new_password.type === "maxLength" && (
                      <span className="error_for_change_pass">Password can't be more than 15 characters*</span>
                    )}
                  {errors.re_new_password &&
                    errors.re_new_password.type === "minLength" && (
                      <span className="error_for_change_pass">Password can't be less than 8 characters*</span>
                    )}
                  {errors.re_new_password &&
                    errors.re_new_password.type === "pattern" && (
                      <span className="error_for_change_pass">Password must be containe uppercase, lowercase andnumber*</span>
                    )}
                  {changePassError.re_new && (
                    <span>{changePassError.re_new}</span>
                  )}
                </div>

                <button type="submit" className="change_password_button">
                  Change password
                </button>
                <button
                  type="button"
                  className="forgot_btn_setting"
                  onClick={() => forgotPassword()}
                >
                  Forgot password?
                </button>
              </form>
            </div>
          </>
        ) : (
          <>
            <hr />
            <div
              className="change_password"
              onClick={() => setChangeSuccess("")}
            >
              <form
                onSubmit={handleSubmit(changePass)}
                onClick={() =>
                  setChangePassError({
                    ...changePassError,
                    re_new: "",
                    current: "",
                  })
                }
              >
                <h2>Change Password</h2>
                {changeSuccess && (
                  <p className="successText">{changeSuccess}</p>
                )}
                <p>
                  If you change your password, you will have to login with your
                  email address{" "}
                  <span style={{ color: "red" }}>({user?.email})</span> and
                  password.
                </p>
                <div>
                  <input
                    type={showPass4 ? "text" : "password"}
                    placeholder="New password"
                    {...register("new_password", {
                      required: true,
                      maxLength: 15,
                      minLength: 8,
                      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
                    })}
                  />
                  {showPass4 ? (
                    <svg
                      onClick={() => setShowPass4(false)}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-eye"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                    </svg>
                  ) : (
                    <svg
                      onClick={() => setShowPass4(true)}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-eye-slash"
                      viewBox="0 0 16 16"
                    >
                      <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                      <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                      <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                    </svg>
                  )}
                  {errors.new_password &&
                    errors.new_password.type === "required" && (
                      <span className="error_for_change_pass">This is required*</span>
                    )}
                  {errors.new_password &&
                    errors.new_password.type === "maxLength" && (
                      <span className="error_for_change_pass">Password can't be more than 15 characters*</span>
                    )}
                  {errors.new_password &&
                    errors.new_password.type === "minLength" && (
                      <span className="error_for_change_pass">Password can't be less than 8 characters*</span>
                    )}
                  {errors.new_password &&
                    errors.new_password.type === "pattern" && (
                      <span className="error_for_change_pass">
                        Password must be containe uppercase, lowercase and
                        number*
                      </span>
                    )}
                </div>

                <div>
                  <input
                    type={showPass5 ? "text" : "password"}
                    {...register("re_new_password", {
                      required: true,
                      maxLength: 15,
                      minLength: 8,
                      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
                    })}
                    placeholder="Re-enter password"
                  />
                  {showPass5 ? (
                    <svg
                      onClick={() => setShowPass5(false)}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-eye"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                    </svg>
                  ) : (
                    <svg
                      onClick={() => setShowPass5(true)}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-eye-slash"
                      viewBox="0 0 16 16"
                    >
                      <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                      <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                      <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                    </svg>
                  )}
                  {errors.re_new_password &&
                    errors.re_new_password.type === "required" && (
                      <span className="error_for_change_pass">This is required*</span>
                    )}
                  {errors.re_new_password &&
                    errors.re_new_password.type === "maxLength" && (
                      <span className="error_for_change_pass">Password can't be more than 15 characters*</span>
                    )}
                  {errors.re_new_password &&
                    errors.re_new_password.type === "minLength" && (
                      <span className="error_for_change_pass">Password can't be less than 8 characters*</span>
                    )}
                  {errors.re_new_password &&
                    errors.re_new_password.type === "pattern" && (
                      <span className="error_for_change_pass">
                        Password must be containe uppercase, lowercase and
                        number*
                      </span>
                    )}
                  {changePassError.re_new && (
                    <span>{changePassError.re_new}</span>
                  )}
                </div>

                <button type="submit" className="change_password_button">
                  Change password
                </button>
              </form>
            </div>
          </>
        )}

        <hr />
        <div className="delete_profile">
          <h2>Delete Account</h2>
          <p>
            Would you like to delete your account? By deleting your account you
            will lose all your data
          </p>
          <button
            onClick={() => setModal({ password: true })}
            className="want_delete"
          >
            I want to delete my account
          </button>
        </div>
      </div>

      {modal.password && (
        <div className="modal_delete_acc">
          <div
            className="overlay_delete_acc"
            onClick={() => setModal({ sure: false, password: false })}
          ></div>
          <div className="modal_content_delete_acc">
            <button
              className="close_delete_acc"
              onClick={() => setModal({ sure: false, password: false })}
            >
              X
            </button>

            <h2>Delete Your Account</h2>
            <p>We're sorry to see you go.</p>
            <p>
              If you delete your account, there is no turning back. But before
              we do anything wrong, we want to make sure it's you.
            </p>
            {user?.auth_provider === "email" ? (
              <form onSubmit={deleteAccount}>
                <label>Password</label>
                <input
                  onChange={(event) => {
                    setCurrent_password(event.target.value);
                    setPassError(false);
                  }}
                  type="password"
                  required
                />

                {passError && (
                  <span className="password_error">
                    Your password is incorrect.
                  </span>
                )}
                <button className="delete_button">
                  Yes, Delete My Account Forever
                </button>
              </form>
            ) : (
              <button
                className="delete_button_google_fb"
                onClick={() => deleteAccWithFbOrGoogle()}
              >
                Delete My Account Forever
              </button>
            )}
          </div>
        </div>
      )}

      {modal.sure && (
        <div className="modal_sure">
          <div
            className="overlay_sure"
            onClick={() => setModal({ sure: false, password: false })}
          ></div>
          <div className="modal_content_sure">
            <p>Are you sure</p>
            <div className="sure_buttons">
              <button onClick={() => yesDelete()}>Yes</button>
              <button
                onClick={() => {
                  setModal({ sure: false, password: false });
                  setCurrent_password("");
                }}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      {loading && <Loading></Loading>}
    </>
  );
}

export default Settings;
