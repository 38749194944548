// import { useEffect } from "react";
// import { Helmet } from "react-helmet";
import { Fade } from "react-bootstrap";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  MailruShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
// import MetaTags from "react-meta-tags";
import "./share.css";
function Share({ postId, setShare, post }) {
  //   useEffect(() => {
  //     console.log(post);
  //     let metaTitle = document.createElement("meta");
  //     metaTitle.property = "title";
  //     metaTitle.content = post.quote_title;
  // console.log(metaTitle);
  //     let metaDesc = document.createElement("meta");
  //     metaDesc.property = "og:description";
  //     metaDesc.content = post.book_author;

  //     let metaImg = document.createElement("meta");
  //     metaImg.property = "og:image";
  //     metaImg.content = post.quote_file

  //     document.getElementsByTagName("head")[0].appendChild(metaTitle)
  //     document.getElementsByTagName("head")[0].appendChild(metaDesc)
  //     document.getElementsByTagName("head")[0].appendChild(metaImg)

  //     let aaa = document.getElementsByTagName("head")[0]
  //     console.log(aaa);

  //     // <!-- <meta property="og:title" content="Social Reading"/> -->
  //     // <!-- <meta property="og:description" content="https://sr.arpify.com" /> -->
  //     // <!-- <meta property="og:image" content="./logo.png" /> -->
  //     // document.createElement(meta)
  //     // document.getElementsByTagName("meta")[4].content = post.book_author;

  //     // document.getElementsByTagName("meta")[5].content = post.quote_title;
  //     // if (post.quote_file) {
  //     //   document.getElementsByTagName("meta")[6].content = post.quote_file;
  //     // }
  //     // let a = document.getElementsByTagName("meta")[4];
  //     // let b = document.getElementsByTagName("meta")[5];
  //     // let c = document.getElementsByTagName("meta")[6];

  //     // console.log(a);
  //     // console.log(b);
  //     // console.log(c);
  //   }, []);
  return (
    <>
      <div className="social_share_modal">
        <div
          className="social_share_overlay"
          onClick={() => setShare(false)}
        ></div>
        <div className="social_share_modal_content">
          <button className="close_share" onClick={() => setShare(false)}>
            X
          </button>
          <FacebookShareButton
            url={`${process.env.REACT_APP_FRONTEND_DOMAIN}/share/${postId}`}
            hashtag={"#reading"}
          >
            <FacebookIcon size={40} round></FacebookIcon>
          </FacebookShareButton>

          <TelegramShareButton
            url={`${process.env.REACT_APP_FRONTEND_DOMAIN}/share/${postId}`}
            title={"Share in Telegram"}
          >
            {/* <Fade up> */}
              <TelegramIcon size={40} round />
            {/* </Fade> */}
          </TelegramShareButton>

          <LinkedinShareButton
            url={`${process.env.REACT_APP_FRONTEND_DOMAIN}/share/${postId}`}
            title={"Share in Linkedin"}
          >
            {/* <Fade up> */}
              <LinkedinIcon size={40} round source={post?.quote_file} />
            {/* </Fade> */}
          </LinkedinShareButton>

          <TwitterShareButton
            url={`${process.env.REACT_APP_FRONTEND_DOMAIN}/share/${postId}`}
            hashtags={["SocialReaing", "reading"]}
            title={"Share in Twitter"}
          >
            {/* <Fade up> */}
              <TwitterIcon size={40} round />
            {/* </Fade> */}
          </TwitterShareButton>

          {/* <ViberShareButton url={`${process.env.REACT_APP_FRONTEND_DOMAIN}/share/${postId}`} title="Share in viber" separator ="sr.arpify.com">
          <ViberIcon size={40} round></ViberIcon>
          </ViberShareButton> */}

          <WhatsappShareButton
            url={`${process.env.REACT_APP_FRONTEND_DOMAIN}/share/${postId}`}
          >
            <WhatsappIcon type="button" size={40} round />
          </WhatsappShareButton>
        </div>
      </div>
    </>
  );
}

export default Share;
