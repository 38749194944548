import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import "./Upload.css";
import EditPost from "../EditPost/EditPost";
import Loading from "../Loading/Loading";

export function Upload() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [categoryErr, setCategoryErr] = useState({
    required: false,
    minLength: false,
    maxLength: false,
  });
  const [modal, setModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoryValue, setCategoryValue] = useState("");
  const [fileOrImageErr, setFileOrImageErr] = useState(false);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [searchModal, setSearchModal] = useState(false);
  const [file, setFile] = useState(undefined);

  // himnakan category
  const suggestions = [
    "Professional",
    "Artistic",
    "Historical",
    "Motivational",
    "Religon",
    "Psychological",
    
  ];
  const [suggestWindow, setSuggestWindow] = useState(false);
  const [user, setUser] = useState("");

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));
    if (token) {
      setUser("JWT " + token.access);
      axios
        .get(process.env.REACT_APP_API_SERVER + "/categories/")
        .then((resp) => {
          setCategories(
            resp.data.map((category) => {
              return category.name;
            })
          );
        });
    }
  }, []);

  // google fonts
  // useEffect(() => {
  //   axios.get("https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyCm5RYrRxcdoJ7RxVJJq12lWT_rto8315A").then(res => {
  //     // setFontFamily(res.data.items)
  //     console.log(res)
  //   }).catch((error) => {
  //     if (error.response) {
  //       console.log("error.response ", error.response);
  //     } else if (error.request) {
  //       console.log("error.request ", error.request);
  //     } else if (error.message) {
  //       console.log("error.request ", error.message);
  //     }
  //   });
  // }, []);

  function showPreview(event) {
    if (event.target.files.length > 0) {
      setFileOrImageErr(false);
      setFile(event.target.files[0]);
      let src = URL.createObjectURL(event.target.files[0]);
      let preview = document.getElementById("file-id-1-preview");
      preview.src = src;
      preview.style.display = "block";
      preview.style.width = "100%";
      preview.style.height = "100%";
      preview.style.borderRadius = "30px";
    }
  }

  const categoryValueChange = (event) => {
    setCategoryErr({
      required: false,
      minLength: false,
      maxLength: false,
      fileRequired: false,
    });

    setCategoryValue(event.target.value);
    if (event.target.value === "") {
      setSuggestWindow(true);
      setSearchModal(false);
    } else {
      setSuggestWindow(false);
      setSearchModal(true);
    }
    const searchedWords = categories.filter((category) => {
      if (category.toLowerCase().startsWith(event.target.value.toLowerCase())) {
        return category;
      }
    });
    searchedWords.push(
      ...categories.filter((category) => {
        if (
          category.toLowerCase().includes(event.target.value.toLowerCase()) &&
          !searchedWords.includes(category)
        ) {
          return category;
        }
      })
    );
    let test = categories.filter((category) => {
      if (category.toLowerCase().includes(event.target.value.toLowerCase())) {
        return category;
      }
    });
    if (test.length === 0) {
      setSearchModal(false);
    }
    setFilteredCategories(searchedWords);
  };

  const toggleModal = () => {
    setFile(undefined);
    setFileOrImageErr(false);
    setModal(!modal);
    setCategoryErr({
      required: false,
      minLength: false,
      maxLength: false,
      fileRequired: false,
    });
    setSearchModal(false);
    setLanguage(false);
    // setSuggestWindow(false);
    setCategoryValue("");
    setUploadText("");
    setVariableForTextOrImage("text");
    reset({ bookName: "" });
    reset({ image: "" });
  };

  const checkCategory = () => {
    if (!file && !uploadText) {
      setFileOrImageErr(true);
    }
    if (!language) {
      setLanguageError(true);
    }
    if (categoryValue === "") {
      setCategoryErr({ ...categoryErr, required: true });
    } else if (categoryValue.length < 2 && categoryValue.length !== "") {
      setCategoryErr({ ...categoryErr, minLength: true });
    } else if (categoryValue.length > 20) {
      setCategoryErr({ ...categoryErr, maxLength: true });
    }
  };

  const [loading, setLoading] = useState(false);

  //upload text or image
  const [uploadText, setUploadText] = useState("");
  const [variableForTextOrImage, setVariableForTextOrImage] = useState("text");

  // language
  const [language, setLanguage] = useState("");
  const [languageError, setLanguageError] = useState(false);
  //upload

  // title
  const [quoteTitle, setQuoteTitle] = useState("");
  const onSubmit = (data) => {
    if (categoryValue && (file || uploadText)) {
      if (
        (variableForTextOrImage === "image" && language) ||
        variableForTextOrImage === "text"
      ) {
        setLoading(true);
        const formData = new FormData();
        formData.append("book_author", data.authorName);
        formData.append("quote_title", data.bookName);
        formData.append("book_category", categoryValue);
        if (uploadText) {
          formData.append("text", uploadText);
          formData.append("language", "eng");
        } else {
          formData.append("text", "");
          formData.append("quote_file", file);
          formData.append("language", language);
        }
        axios
          .post(process.env.REACT_APP_API_SERVER + "/quotes/", formData, {
            headers: { Authorization: user },
          })
          .then((resp) => {
            console.log(resp.data);
            setId(resp.data.id);
            setQuoteText(resp.data.quote_text);
            setTextEditor(true);
            setLoading(false);
            setQuoteCategory(resp.data.book_category);
            setQuoteTitle(resp.data.quote_title);
          })
          .catch((error) => {
            if (error.response) {
              console.log("error.response ", error.response);
              setLoading(false);
              alert("error");
            } else if (error.request) {
              console.log("error.request ", error.request);
              setLoading(false);
              alert("error");
            } else if (error.message) {
              console.log("error.message ", error.message);
              setLoading(false);
              alert("error");
            }
          });
        toggleModal();
      }
    } else if (!file || !uploadText) {
      setFileOrImageErr(true);
    }
  };

  //text editor
  const [textEditor, setTextEditor] = useState(false);
  const [quoteText, setQuoteText] = useState("");
  const [id, setId] = useState("");
  const [textStyle, setTextStyle] = useState({
    color: "#000000",
    font: "",
    hedline: "",
    background: "white",
    size: "16px",
  });
  const [quoteCategory, setQuoteCategory] = useState("");

  // prevent scrolling
  useEffect(() => {
    if (modal || textEditor || loading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modal, textEditor, loading]);

  return (
    <>
      <button onClick={toggleModal} className="btn-modal bi bi-cloud-upload">
        &nbsp;Upload
      </button>
      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content">
            <button className="close" onClick={toggleModal}>
              X
            </button>
            <div className="upload_all_content">
              {/* choose image or text */}
              <div className="choose_image_or_text">
                {/* <p>Please choose upload text or image</p> */}
                {variableForTextOrImage === "text" ? (
                  <button
                    onClick={() => {
                      setVariableForTextOrImage("image");
                      setLanguageError(false);
                      reset();
                      setFileOrImageErr(false);
                      setLanguage("");
                      setUploadText("");
                    }}
                  >
                    Choose image
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setVariableForTextOrImage("text");
                      setLanguageError(false);
                      reset();
                      setFileOrImageErr(false);
                      setLanguage("");
                    }}
                  >
                    Upload text
                  </button>
                )}
                <p>or</p>
              </div>

              {/* upload image */}
              {variableForTextOrImage === "image" && (
                <>
                  <div>
                    <div className="imageDiv">
                      <img id="file-id-1-preview" alt=""/>
                    </div>
                    <label htmlFor="files" className="fileLabel">
                      Select Image
                    </label>
                    <input
                      type="file"
                      id="files"
                      accept="image/png, image/jpeg, image/jpg"
                      className="fileInput"
                      onChange={showPreview}
                    />
                    {fileOrImageErr && variableForTextOrImage === "image" && (
                      <p className="fileRequired">This field is required*</p>
                    )}
                  </div>
                  {/* language */}
                  <div className="language_select_div" defaultValue={language}>
                    <select
                      onChange={(e) => {
                        setLanguage(e.target.value);
                        setLanguageError(false);
                      }}
                    >
                      <option value="" disabled selected>
                        Choose upload language
                      </option>
                      <option value="eng">English</option>
                      <option value="hye">Armenian</option>
                      <option value="rus">Russian</option>
                      <option value="fra">French</option>
                      <option value="hin">Hindi</option>
                    </select>
                    <br />
                    {languageError && (
                      <span className="fileRequired">
                        This field is required*
                      </span>
                    )}
                  </div>
                </>
              )}

              {/* upload text */}
              {variableForTextOrImage === "text" && (
                <div className="upload_text_div">
                  <textarea
                    onChange={(e) => {
                      setUploadText(e.target.value);
                      setFileOrImageErr(false);
                    }}
                    placeholder="Write your text here..."
                  />
                  {fileOrImageErr && variableForTextOrImage === "text" && (
                    <p className="fileRequired">This field is required*</p>
                  )}
                </div>
              )}

              <form
                onSubmit={handleSubmit(onSubmit)}
                onClick={() => {
                  // setSuggestWindow(false);
                  setSearchModal(false);
                }}
                className="upload_form"
              >
                {/* author name */}
                <div>
                  <input
                    autoComplete="off"
                    className="authorName"
                    placeholder="Author"
                    id="name"
                    type="text"
                    {...register("authorName", {
                      required: true,
                      maxLength: 20,
                      minLength: 2,
                    })}
                  />
                  <br />
                  {errors.authorName &&
                    errors.authorName.type === "required" && (
                      <span className="fileRequired">
                        This field is required*
                      </span>
                    )}
                  {errors.authorName &&
                    errors.authorName.type === "maxLength" && (
                      <span className="authorNameErr">
                        The field must contain no more than 20 characters
                      </span>
                    )}
                  {errors.authorName &&
                    errors.authorName.type === "minLength" && (
                      <span className="authorNameErr">
                        The field must contain at least 2 characters
                      </span>
                    )}
                </div>

                {/* book name */}
                <div>
                  <input
                    autoComplete="off"
                    className="bookName"
                    placeholder="Book name"
                    id="name"
                    type="text"
                    {...register("bookName", {
                      required: true,
                      maxLength: 20,
                      minLength: 2,
                    })}
                  />
                  <br />
                  {errors.bookName && errors.bookName.type === "required" && (
                    <span className="fileRequired">
                      This field is required*
                    </span>
                  )}
                  {errors.bookName && errors.bookName.type === "maxLength" && (
                    <span className="bookNameErr">
                      The field must contain no more than 20 characters
                    </span>
                  )}
                  {errors.bookName && errors.bookName.type === "minLength" && (
                    <span className="bookNameErr">
                      The field must contain at least 2 characters
                    </span>
                  )}
                </div>

                {/* category */}
                <div className="category_div">
                  <input
                    value={categoryValue}
                    onChange={(event) => categoryValueChange(event)}
                    onClick={(e) => {
                      if (!e.target.value) setSuggestWindow(true);
                    }}
                    autoComplete="off"
                    className="bookCategory"
                    placeholder="Category"
                    id="name"
                    type="text"
                  />
                  <p className="search_img">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="grey"
                      className="bi bi-search"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                  </p>

                  <div className="category_search">
                    {categoryErr.required && (
                      <p className="fileRequired">This field is required*</p>
                    )}
                    {categoryErr.minLength && (
                      <p className="categoryErr">
                        The field must contain at least 2 characters
                      </p>
                    )}
                    {categoryErr.maxLength && (
                      <p className="categoryErr">
                        The field must contain no more than 20 characters
                      </p>
                    )}
                  </div>
                  {suggestWindow && (
                    <div
                      className="suggestions"
                      onClick={() => {
                        // setSuggestWindow(false);
                      }}
                    >
                      {suggestions.map((suggest, index) => {
                        return (
                          <p
                            key={index}
                            className="suggestedWords"
                            onClick={() => {
                              setCategoryValue(suggest);
                              setSuggestWindow(false);
                            }}
                          >
                            {suggest}
                          </p>
                        );
                      })}
                    </div>
                  )}
                  {searchModal && (
                    <div
                      className="searches"
                      onClick={() => {
                        setSearchModal(false);
                      }}
                    >
                      {filteredCategories.map((category, index) => {
                        return (
                          <p
                            key={index}
                            className="searchedWords"
                            onClick={() => {
                              setCategoryValue(category);
                              setSearchModal(false);
                            }}
                          >
                            {category}
                          </p>
                        );
                      })}
                    </div>
                  )}
                </div>
                {/* upload */}
                <input
                  // onClick= {() => {

                  // }}
                  // disabled={!variableForTextOrImage}
                  // style={!variableForTextOrImage && { background: "grey", color:"lightGrey "}}
                  className="submit"
                  type="submit"
                  value="Upload"
                  onClick={checkCategory}
                />
              </form>
            </div>
          </div>
        </div>
      )}

      {/* text Editor */}
      {textEditor && (
        <EditPost
          id={id}
          quoteText={quoteText}
          textStyle={textStyle}
          setTextEditor={setTextEditor}
          setTextStyle={setTextStyle}
          setQuoteText={setQuoteText}
          quoteCategory={quoteCategory}
          quote_title={quoteTitle}
        />
      )}

      {/* loading */}
      {loading && <Loading />}
    </>
  );
}
