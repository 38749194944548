import "./google.css";
import axios from "axios";
import {  useEffect } from "react";
import GoogleLogin from "react-google-login";
import { gapi } from "gapi-script";
import googleIcon from "./../../assets/images/google.png"
import { useNavigate } from "react-router-dom";
// import { Context } from "../Router";
const googleClientId = "921494347682-l9gq1nedhrignfafbqomtlsacc8bmmbd.apps.googleusercontent.com";

function LoginGoogle() {
const navigate = useNavigate()
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: googleClientId,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  // const data = useContext(Context);


//google login
  const onSuccess = (res) => {
    console.log("google data", res);
    axios
      .post(process.env.REACT_APP_API_SERVER+"/social_auth/google/", {
        auth_token: res.tokenId,
      }).then((res) => {
        console.log(res.data);
        let a = "JWT " + res.data.access;
        axios
          .get(process.env.REACT_APP_API_SERVER+"/auth/users/me/", {
            headers: { Authorization: a },
          })
          .then((response) => {
            console.log("user info", response.data);
            localStorage.setItem("token", JSON.stringify(res.data));
            localStorage.setItem("user", JSON.stringify(response.data));
            navigate("/")
            window.location.reload();
          })
          .catch((error) => {
            if (error.response) {
              console.log("error.response", error.response);
            } else if (error.request) {
              console.log("error.request ", error.request);
            } else if (error.message) {
              console.log("error.message ", error.message);
            }
          });
      })
      .catch((error) => {
        if (error.response) {
          console.log("error.response  google ", error.response);
          if (error.response.data.detail) {
            alert("User with this email already exists.")
          }
        } else if (error.request) {
          console.log("error.request ", error.request);
        } else if (error.message) {
          console.log("error.message ", error.message);
        }
      });
  };

  const onFailure = (res) => {
    alert("failed login", res);
  };



  return (
      <GoogleLogin
        render={(renderProps) => (
          <button
            className="google"
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            <img
              alt="google_icon"
              src={googleIcon}
              width="18px"
            />
          </button>
        )}
        clientId={googleClientId}
        buttonText=""
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
        isSignedIn={true}
        onAutoLoadFinished={true}
      />
      
  
  );
}

export default LoginGoogle;
