import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import Post from "../../components/Post/Post";
import "./defaultCategory.css";

function DefaultCategory() {
  const params = useParams();
  const [posts, setPosts] = useState([]);
  const [user, setUser] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
    getThisCategoryPosts();
  }, [params.category]);
  function getThisCategoryPosts() {
    setLoading(true)

    axios
      .get(
        `${process.env.REACT_APP_API_SERVER}/quotes/?category=${params?.category}`
      )
      .then((resp) => {
        console.log(resp.data);
        setPosts(resp.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log("error.response ", error.response);
        } else if (error.request) {
          console.log("error.request ", error.request);
        } else if (error.message) {
          console.log("error.message ", error.message);
        }
      });
  }
  return (
    <>
     <div className="all_posts_in_category">
      {posts.map((post) => {
        if (
          post?.published ||
          (!post?.published && post?.author?.id === user?.id)
        ) {
          return (
            <div key={post?.id}>
              <Post post={post} />
            </div>
          );
        } else {
          return "";
        }
      })}
    </div>
    {loading && <Loading></Loading>}
    </>
   
  );
}
export default DefaultCategory;
