import axios from "axios";
import FacebookLogin from "react-facebook-login";
import { useNavigate } from "react-router-dom";
import "./signInFb.css"

const appId = "912686513458339";

function LoginFb() {
  const navigate = useNavigate();

  const responseFacebook = (response) => {
    console.log("hesa", response);
    console.log("token hesa", response.accessToken);
    axios
      .post(process.env.REACT_APP_API_SERVER + "/social_auth/facebook/ ", {
        auth_token: response.accessToken,
      })
      .then((res) => {
        console.log(res.data);
        let a = "JWT " + res.data.access;
        axios
          .get(process.env.REACT_APP_API_SERVER + "/auth/users/me/", {
            headers: { Authorization: a },
          })
          .then((response) => {
            console.log("act fb", response.data);
            localStorage.setItem("token", JSON.stringify(res.data));
            localStorage.setItem("user", JSON.stringify(response.data));
            navigate("/");
            window.location.reload();
          })
          .catch((error) => {
            if (error.response) {
              console.log("error.response ", error.response);
            } else if (error.request) {
              console.log("error.request ", error.request);
            } else if (error.message) {
              console.log("error.message ", error.message);
            }
          });
      })
      .catch((error) => {
        if (error.response) {
          console.log("error.response ", error.response);
          navigate("/");
          if (error.response.data.auth_token) {
            alert("User with this email already exists.");
          }
        } else if (error.request) {
          console.log("error.request ", error.request);
          navigate("/");
        } else if (error.message) {
          console.log("error.message ", error.message);
          navigate("/");
        }
      });
  };

  return (
    <>
      <FacebookLogin
        appId={appId}
        fields="name,email,picture"
        callback={responseFacebook}
        cssClass="my-facebook-button-class"
        icon="fa-facebook"
        textButton=""
      />
    </>
  );
}

export default LoginFb;
