import "./aboutUs.css"
function AboutUs() {
  return (
    <>
      <div className="about_us_section">
        <h1>About Us</h1>
        <p>
          SR provides the users opportunity to keep all the essential captures
          from their favourite books in one place. Books offer a wide breadth of
          information, experience, and knowledge. Books tell us about other
          people, other countries, other customs and cultures. Books help us to
          teach ourselves about history, the arts, science, religion, nature,
          mathematics, and technology–anything and everything in our universe
          and beyond. Books also help us to understand the effect that all those
          things have on us and our world. SR aims to collect all book lovers in
          one place, as well as it tries to broaden the importance of reading.
        </p>
      </div>
    </>
  );
}

export default AboutUs;
